<template>
  <div>
    <b-container>
      <b-row class="my-4">
        <b-col>
          <b-input-group>
            <b-form-input v-model="imageUrl" placeholder="Enter image URL"></b-form-input>
            <b-input-group-append>
              <b-button variant="primary" @click="loadImage">Load Image</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="canvas-container">
            <canvas ref="canvas" @mousedown="startMask" @mousemove="handleMouseMove" @mouseup="endMask"></canvas>
            <canvas ref="hoverCanvas" class="hover-canvas"></canvas>
            <div v-if="isLoading" class="spinner-overlay">
              <b-spinner variant="primary" label="Loading..."></b-spinner>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="my-4">
        <b-col>
          <b-button variant="success" @click="cleanup" class="mr-2">
            Cleanup
          </b-button>
          <b-button variant="secondary" @click="undo" :disabled="undoStack.length === 0" class="mr-2">
            Undo
          </b-button>
          <b-button variant="secondary" @click="redo" :disabled="redoStack.length === 0">
            Redo
          </b-button>
          <span>Brush Size: {{ maskRadius }}</span>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      imageUrl: '',
      maskRadius: 10,
      isDrawingMask: false,
      isMaskOutlineVisible: false,
      maskPositions: [],
      undoStack: [],
      redoStack: [],
      apiKey: '1fb1c6bfb2745669796d6a1ea54b0c5f931e78f1bfc5d19e57f219a55de7d388f9c466495c3a2fe9d0ab5b52c0976783',
      backgroundImage: null,
      isLoading: false,
    };
  },
  methods: {
    loadImage() {
      const proxyUrl = this.imageUrl;
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = proxyUrl;
      img.onload = () => {
        const canvas = this.$refs.canvas;
        const hoverCanvas = this.$refs.hoverCanvas;
        const ctx = canvas.getContext('2d');
        canvas.width = 1000;
        canvas.height = 1000;
        hoverCanvas.width = canvas.width;
        hoverCanvas.height = canvas.height;
        const centerX = (canvas.width - img.width) / 2;
        const centerY = (canvas.height - img.height) / 2;
        ctx.drawImage(img, centerX, centerY);
        this.backgroundImage = img;
        this.showMaskOutline({ offsetX: 0, offsetY: 0 });
      };
    },
    updateCanvasOffset() {
      const canvas = this.$refs.canvas;
      const rect = canvas.getBoundingClientRect();
      this.canvasOffset = { left: rect.left, top: rect.top };
    },
    showMaskOutline(event) {
      if (!this.backgroundImage) return;

      const canvas = this.$refs.canvas;
      const hoverCanvas = this.$refs.hoverCanvas;
      const rect = canvas.getBoundingClientRect();
      const scaleX = canvas.width / rect.width;
      const scaleY = canvas.height / rect.height;
      const ctx = hoverCanvas.getContext('2d');
      ctx.clearRect(0, 0, hoverCanvas.width, hoverCanvas.height);

      if (this.isMaskOutlineVisible) {
        const offsetX = (event.clientX - rect.left) * scaleX;
        const offsetY = (event.clientY - rect.top) * scaleY;
        ctx.strokeStyle = 'rgba(0, 0, 0, 0.8)';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.arc(offsetX, offsetY, this.maskRadius, 0, 2 * Math.PI);
        ctx.stroke();
      }

      this.maskPositions.forEach(([x, y]) => {
        const mainCtx = canvas.getContext('2d');
        mainCtx.fillStyle = 'rgb(255, 255, 255)';
        mainCtx.beginPath();
        mainCtx.arc(x, y, this.maskRadius, 0, 2 * Math.PI);
        mainCtx.fill();
      });
    },
    handleMouseMove(event) {
      this.showMaskOutline(event);
      this.drawMask(event);
    },
    startMask(event) {
      this.isDrawingMask = true;
      this.maskPositions = [[event.offsetX, event.offsetY]];
      this.isMaskOutlineVisible = false;
      this.undoStack.push(JSON.parse(JSON.stringify(this.maskPositions)));
      this.redoStack = [];
    },
    drawMask(event) {
      if (!this.isDrawingMask) return;
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext('2d');
      ctx.fillStyle = 'rgb(255, 255, 255)';
      ctx.beginPath();
      ctx.arc(event.offsetX, event.offsetY, this.maskRadius, 0, 2 * Math.PI);
      ctx.fill();
      this.maskPositions.push([event.offsetX, event.offsetY]);
    },
    endMask() {
      this.isDrawingMask = false;
      this.isMaskOutlineVisible = true;
    },
    async cleanup() {
      const canvas = this.$refs.canvas;
      const maskCanvas = document.createElement('canvas');
      maskCanvas.width = canvas.width;
      maskCanvas.height = canvas.height;
      const maskCtx = maskCanvas.getContext('2d');
      maskCtx.fillStyle = 'black';
      maskCtx.fillRect(0, 0, maskCanvas.width, maskCanvas.height);
      maskCtx.fillStyle = 'white';
      this.maskPositions.forEach(([x, y]) => {
        maskCtx.beginPath();
        maskCtx.arc(x, y, this.maskRadius, 0, 2 * Math.PI);
        maskCtx.fill();
      });

      try {
        this.isLoading = true;
        const formData = new FormData();
        const imageBlob = await this.canvasToBlob(canvas, this.backgroundImage);
        const maskBlob = await this.canvasToBlob(maskCanvas);

        formData.append('image_file', imageBlob, 'image.png');
        formData.append('mask_file', maskBlob, 'mask.png');
        formData.append('mode', 'quality');

        console.log('Request Data:');
        for (const [key, value] of formData.entries()) {
          console.log(`${key}: ${value}`);
        }

        const response = await axios.post('https://clipdrop-api.co/cleanup/v1', formData, {
          headers: {
            'x-api-key': this.apiKey,
          },
          responseType: 'arraybuffer',
        });

        console.log('Response Data:', response.data);

        const blob = new Blob([response.data], { type: 'image/png' });
        const url = URL.createObjectURL(blob);
        const img = new Image();
        img.src = url;
        img.onload = () => {
          const ctx = canvas.getContext('2d');
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          const centerX = (canvas.width - img.width) / 2;
          const centerY = (canvas.height - img.height) / 2;
          ctx.drawImage(img, centerX, centerY);
          this.backgroundImage = img;
          this.maskPositions = [];
          this.undoStack = [];
          this.redoStack = [];
          this.isLoading = false;
        };
      } catch (error) {
        console.error('Error:', error.response);
        if (error.response) {
          console.error('Error Status:', error.response.status);
          
          // 응답 데이터를 문자열로 변환하여 출력
          const decoder = new TextDecoder('utf-8');
          const errorMessage = decoder.decode(new Uint8Array(error.response.data));
          console.error('Error Message:', errorMessage);
        }
        this.isLoading = false;
      }
    },
    undo() {
      if (this.undoStack.length === 0) return;
      this.redoStack.push(this.maskPositions);
      this.maskPositions = this.undoStack.pop();
      this.showMaskOutline({ offsetX: 0, offsetY: 0 });
    },
    redo() {
      if (this.redoStack.length === 0) return;
      this.undoStack.push(this.maskPositions);
      this.maskPositions = this.redoStack.pop();
      this.showMaskOutline({ offsetX: 0, offsetY: 0 });
    },
    canvasToBlob(canvas) {
      return new Promise((resolve) => {
        canvas.toBlob((blob) => {
          resolve(blob);
        }, 'image/png');
      });
    },
  },
  mounted() {
    window.addEventListener('keydown', (event) => {
      if (event.ctrlKey && event.key === 'z') {
        this.undo();
      } else if (event.ctrlKey && event.key === 'y') {
        this.redo();
      } else if (event.key === '[') {
        this.maskRadius = Math.max(1, this.maskRadius - 1);
      } else if (event.key === ']') {
        this.maskRadius = Math.min(50, this.maskRadius + 1);
      }
    });

    const canvas = this.$refs.canvas;
    const hoverCanvas = this.$refs.hoverCanvas;
    hoverCanvas.width = canvas.width;
    hoverCanvas.height = canvas.height;

    canvas.addEventListener('mouseenter', () => {
      this.isMaskOutlineVisible = true;
    });
    canvas.addEventListener('mouseleave', () => {
      this.isMaskOutlineVisible = false;
    });
    window.addEventListener('resize', this.updateCanvasOffset);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateCanvasOffset);
  },
};
</script>

<style scoped>
.canvas-container {
  position: relative;
  width: 1000px;
  height: 1000px;
}

canvas {
  cursor: crosshair;
  border: 1px solid #ccc;
}

.hover-canvas {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1;
}
</style>